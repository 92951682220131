<template>
	<div>
		<label
			v-if="!floatingLabel"
			:for="id"
		>
			<slot name="label">
				<WwLabel
					v-if="label.length"
					:class="[
						labelSize,
						labelClass,
						'min-h-5'
					]"
				>
					{{ label }}
					<span
						v-if="required && showRequiredAstrisk"
						:class="[
							labelSize,
							'text-red-500 lowercase'
						]"
					>
						*
					</span>
				</WwLabel>
			</slot>
		</label>
		<div class="relative">
			<slot name="input">
				<WwTextInputStateIcon
					v-if="displayIcon"
					:state="state"
					:position="iconPosition"
				/>

				<input
					v-if="isStandardTextInput && !floatingLabel"
					:id="id"
					:type="inputType"
					class="px-3 placeholder-gray-500 border-gray-300 rounded-md h-11 focus:outline-none focus:ring-2 focus:border-gray-300"
					:class="[
						inputClass,
						borderValidationClass,
						borderClass,
						{ 'bg-gray-200' : disabled },
						{ 'pl-8' : displayIcon && iconPosition == LEFT },
						`focus:ring-${ringColor}`,
						bgColor
					]"
					:autocomplete="autocomplete"
					:placeholder="placeholder"
					:value="value"
					:disabled="disabled"
					:required="required"
					@input="$emit('input', $event.target.value)"
					@blur="handleBlur"
				>

				<div
					v-if="isStandardTextInput && floatingLabel"
					class="relative"
				>
					<input
						:id="id"
						class="w-full text-black placeholder-transparent border-gray-300 outline-none appearance-none peer autofill:bg-green-300 focus:ring-0 focus:outline-none focus:border-t-transparent focus:border-x-transparent border-t-transparent border-x-transparent"
						:type="inputType"
						:class="[
							inputClass,
							borderValidationClass,
							borderClass,
							{ 'rounded-md' : rounded },
							{ 'bg-gray-200' : disabled },
							{ 'pl-8' : displayIcon && iconPosition == LEFT },
							`focus:ring-${ringColor}`,
							bgColor,
							showErrorMessage ? 'invalid:border-b-red-500' : 'focus:border-b-green-500'
						]"
						:autocomplete="autocomplete"
						:placeholder="placeholder"
						:value="value"
						:disabled="disabled"
						:required="required"
						@input="$emit('input', $event.target.value)"
						@blur="handleBlur"
					>
					<Transition name="fade-and-slide">
						<div
							v-if="showErrorMessage"
							class="pl-1 mt-1 text-xs italic text-red-500"
						>
							{{ errorMessage }}
						</div>
					</Transition>
					<label
						v-if="floatingLabel"
						:for="id"
						:class="[
							'absolute left-0 focus-within:left-0 peer-focus:-top-6 -top-6 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:left-0 peer-focus:text-gray-500 peer-focus:text-sm',
							{ 'peer-placeholder-shown:left-2' : rounded },
							{ '-top-6' : value.length }
						]"
					>
						<span
							:class="[
								labelSize,
								labelClass
							]"
						>
							{{ label }}
						</span>
						<span
							v-if="required && showRequiredAstrisk"
							:class="[
								labelSize,
								'text-red-500 lowercase'
							]"
						>
							*
						</span>
					</label>
				</div>
				<input
					v-if="inputType === 'tel'"
					v-model="phoneNumber"
					type="tel"
					class="px-3 placeholder-gray-500 border-gray-300 rounded-md h-11 focus:outline-none focus:ring-2 focus:border-gray-300"
					:class="[
						inputClass,
						borderValidationClass,
						borderClass,
						{ 'bg-gray-200' : disabled },
						{ 'pl-8' : displayIcon && iconPosition === LEFT },
						`focus:ring-${ringColor}`,
						bgColor
					]"
					:autocomplete="autocomplete"
					:placeholder="placeholder"
					:disabled="disabled"
					:required="required"
					@blur="handleBlur"
				>
				<textarea
					v-if="inputType === 'textarea'"
					class="px-3 placeholder-gray-500 border-gray-300 rounded-md h-11 focus:outline-none focus:ring-2 focus:border-gray-300"
					:class="[
						inputClass,
						borderValidationClass,
						borderClass,
						{ 'bg-gray-200' : disabled },
						{ 'pl-8' : displayIcon && iconPosition === LEFT },
						`focus:ring-${ringColor}`,
						bgColor
					]"
					:placeholder="placeholder"
					:value="value"
					:disabled="disabled"
					:required="required"
					@input="$emit('input', $event.target.value)"
					@blur="handleBlur"
				/>
				<template v-if="icon.length">
					<SearchIcon
						v-if="icon === SEARCH"
						:class="[`absolute w-5 h-5 transform -translate-y-1/2 pointer-events-none ${position}-2 top-1/2`]"
					/>
				</template>
			</slot>
		</div>
		<transition name="fade-and-slide">
			<slot name="accordion">
				<div
					v-if="showErrorMessage && !floatingLabel"
					class="pl-1 text-xs italic text-red-500"
				>
					{{ errorMessage }}
				</div>
			</slot>
		</transition>
	</div>
</template>

<script async>

import SearchIcon from '@/components/icons/SearchIcon.vue'
import WwLabel from '@/components/UI/helpers/WwLabel.vue'
import WwTextInputStateIcon from '@/components/UI/helpers/WwTextInputStateIcon.vue'
import { SEARCH_ICON } from '@/constants/text-inputs/icons.js'
import { ERROR, NONE } from '@/constants/text-inputs/states.js'
import { DATE, EMAIL, NUMBER, PASSWORD, SEARCH, TEL, TEXT, TEXTAREA, ZIP } from '@/constants/text-inputs/types.js'
import { inputState, inputType } from '@/validators/text-inputs.js'

const LEFT = 'left'
const RIGHT = 'right'

export default {
	components: {
		SearchIcon,
		WwLabel,
		WwTextInputStateIcon
	},
	props: {
		state: {
			type: String,
			default: NONE,
			validator: inputState
		},
		label: {
			type: String,
			default: ''
		},
		floatingLabel: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			required: true
		},
		value: {
			type: [ Number, String ],
			required: true
		},
		placeholder: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		inputClass: {
			type: String,
			default: ''
		},
		borderClass: {
			type: String,
			default: 'border-2'
		},
		inputType: {
			type: String,
			default: TEXT,
			validator: inputType
		},
		labelClass: {
			type: String,
			default: ''
		},
		labelSize: { // any tailwind text size class
			type: String,
			default: 'text-sm'
		},
		ringColor: { // any tailwind color
			type: String,
			default: 'green-300'
		},
		icon: {
			type: String,
			default: ''
		},
		showIcon: {
			type: Boolean,
			default: false
		},
		iconLeft: {
			type: Boolean,
			default: true
		},
		iconRight: {
			type: Boolean,
			default: false
		},
		autocomplete: {
			type: String,
			default: 'on'
		},
		showErrorMessageOnInvalid: {
			type: Boolean,
			default: true
		},
		errorMessage: {
			type: String,
			default: 'Please enter a valid response'
		},
		showRequiredAstrisk: {
			type: Boolean,
			default: true
		},
		bgColor: {
			type: String,
			default: ''
		}
	},
	emits: [ 'updated', 'input', 'validity-changed', 'blur' ],
	data() {
		return {
			NONE,
			SEARCH_ICON,
			TEXT,
			TEXTAREA,
			EMAIL,
			PASSWORD,
			NUMBER,
			DATE,
			SEARCH,
			TEL,
			ZIP,
			LEFT,
			RIGHT,
			display: null,
			valid: false,
			enableErrorMessage: false,
			AsYouType: null,
			parsePhoneNumberFromString: null,
			countryCode: 'US'
		}
	},
	computed: {
		phoneNumber: {
			get: function () {
				if (this.display) return this.display
				if (!this.value) return
				const number = this.parsePhoneNumberFromString(this.value, this.countryCode)
				return number.formatNational()
			},
			set: function (newPhone) {
				const asYouType = new this.AsYouType(this.countryCode)
				this.display = asYouType.input(newPhone)
				const digits = asYouType.getNationalNumber()
				this.valid = asYouType.isValid()
				this.$emit('input', digits)
				this.$emit('updated', asYouType)
				this.$emit('validity-changed', this.valid)
			}
		},
		borderValidationClass() {
			if (!this.value?.length) { return '' }
			return {
				'focus:border-red-500 border-red-500': this.state === ERROR
			}
		},
		isExpanded() {
			return this.state === ERROR
		},
		displayIcon() {
			if (this.isStandardTextInput || this.isTelephoneInput) {
				return this.required && this.showIcon
			}
			return false
		},
		iconPosition() {
			return !this.iconLeft || this.iconRight ? RIGHT : LEFT
		},
		isStandardTextInput() {
			const standardInputs = [ TEXT, SEARCH, DATE, NUMBER, PASSWORD, EMAIL, ZIP ]
			return standardInputs.includes(this.inputType)
		},
		isTelephoneInput() {
			return this.inputType === TEL
		},
		showErrorMessage() {
			return this.isExpanded && this.required && this.showErrorMessageOnInvalid && this.enableErrorMessage
		}
	},
	async created() {
		if (this.inputType === TEL) {
			const {
				AsYouType,
				parsePhoneNumberFromString
			} = await import('libphonenumber-js')
			this.AsYouType = AsYouType
			this.parsePhoneNumberFromString = parsePhoneNumberFromString
		}
	},
	methods: {
		handleBlur() {
			this.enableErrorMessage = true
			this.$emit('blur')
		}
	}
}
</script>
