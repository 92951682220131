<template>
	<div v-if="state !== NONE">
		<div
			v-if="state === SUCCESS"
			:class="[`absolute w-5 h-5 transform -translate-y-1/2 bg-green-500 rounded-full pointer-events-none ${position}-2 top-1/2`]"
		/>

		<div
			v-else-if="state === ERROR"
			:class="[`absolute w-5 h-5 text-white transform -translate-y-1/2 bg-red-500 rounded-full pointer-events-none ${position}-2 top-1/2`]"
		>
			<div class="flex items-center justify-center text-sm font-bold text-center">
				!
			</div>
		</div>
		<div
			v-else-if="state === LOADING"
			:class="[`absolute w-5 h-5 transform -translate-y-1/2 bg-yellow-500 rounded-full pointer-events-none ${position}-2 top-1/2`]"
		/>
	</div>
</template>

<script async>
import { ERROR, LOADING, NONE, SUCCESS } from '@/constants/text-inputs/states.js'
import { inputState } from '@/validators/text-inputs.js'

export default {
	props: {
		state: {
			type: String,
			default: NONE,
			validator: inputState
		},
		position: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			NONE,
			SUCCESS,
			ERROR,
			LOADING
		}
	}
}
</script>
