import PRECISION_RANGE from '@/constants/text-inputs/precision-range.js'
import PRECISION_TYPES from '@/constants/text-inputs/precision-types.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import INPUT_TYPES from '@/constants/text-inputs/types.js'
import { logError } from '@/utils/error-handling.js'

export const inputState = value => {
	const isValid = Object.keys(INPUT_STATES)
		.map(type => type.toLowerCase())
		.indexOf(value.toLowerCase()) !== -1

	if (typeof value !== 'string' || !isValid) {
		logError(`Invalid input state provided: ${value}`)
	}
	return isValid
}

export const inputType = value => {
	const isValid = Object.keys(INPUT_TYPES)
		.map(type => type.toLowerCase())
		.indexOf(value.toLowerCase()) !== -1
	if (typeof value !== 'string' || !isValid) {
		logError(`Invalid input type provided: ${value}`)
	}
	return isValid
}

export const precisionType = value => {
	const isValid = Object.keys(PRECISION_TYPES)
		.map(type => type.toLowerCase())
		.indexOf(value.toLowerCase()) !== -1

	if (typeof value !== 'string' || !isValid) {
		logError(`Invalid precision type provided: ${value}`)
	}
	return isValid
}

export const precisionValue = value => {
	const dataType = typeof value
	let isValid = false

	switch (dataType) {
		case 'number':
			if (value % 1 === 0) {
				isValid = true
			}
			break
		case 'object':
			for (const item in Object.keys(value)) {
				const key = Object.keys(value)[item]
				const containsKey = Object.keys(PRECISION_RANGE)
					.map(property => property.toLowerCase())
					.indexOf(key) !== -1
				if (containsKey) {
					isValid = value[key] % 1 === 0
				} else {
					break
				}
			}
			break
		default:
			isValid = false
	}

	if (!isValid) {
		logError(`Invalid precision value provided: ${value}`)
	}
	return isValid
}

export const inputFieldState = (value, type) => {
	if (type === INPUT_TYPES.EMAIL) {
		return value?.length && value.includes('@') ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
	} else if (type === INPUT_TYPES.ZIP) {
		const usPostalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
		const canadaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
		return usPostalCodeRegex.test(value) || canadaPostalCodeRegex.test(value) ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
	}
	return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
}
