var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.floatingLabel)?_c('label',{attrs:{"for":_vm.id}},[_vm._t("label",function(){return [(_vm.label.length)?_c('WwLabel',{class:[
					_vm.labelSize,
					_vm.labelClass,
					'min-h-5'
				]},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.label)+"\n\t\t\t\t"),(_vm.required && _vm.showRequiredAstrisk)?_c('span',{class:[
						_vm.labelSize,
						'text-red-500 lowercase'
					]},[_vm._v("\n\t\t\t\t\t*\n\t\t\t\t")]):_vm._e()]):_vm._e()]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative"},[_vm._t("input",function(){return [(_vm.displayIcon)?_c('WwTextInputStateIcon',{attrs:{"state":_vm.state,"position":_vm.iconPosition}}):_vm._e(),_vm._v(" "),(_vm.isStandardTextInput && !_vm.floatingLabel)?_c('input',{staticClass:"px-3 placeholder-gray-500 border-gray-300 rounded-md h-11 focus:outline-none focus:ring-2 focus:border-gray-300",class:[
					_vm.inputClass,
					_vm.borderValidationClass,
					_vm.borderClass,
					{ 'bg-gray-200' : _vm.disabled },
					{ 'pl-8' : _vm.displayIcon && _vm.iconPosition == _vm.LEFT },
					`focus:ring-${_vm.ringColor}`,
					_vm.bgColor
				],attrs:{"id":_vm.id,"type":_vm.inputType,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":_vm.handleBlur}}):_vm._e(),_vm._v(" "),(_vm.isStandardTextInput && _vm.floatingLabel)?_c('div',{staticClass:"relative"},[_c('input',{staticClass:"w-full text-black placeholder-transparent border-gray-300 outline-none appearance-none peer autofill:bg-green-300 focus:ring-0 focus:outline-none focus:border-t-transparent focus:border-x-transparent border-t-transparent border-x-transparent",class:[
						_vm.inputClass,
						_vm.borderValidationClass,
						_vm.borderClass,
						{ 'rounded-md' : _vm.rounded },
						{ 'bg-gray-200' : _vm.disabled },
						{ 'pl-8' : _vm.displayIcon && _vm.iconPosition == _vm.LEFT },
						`focus:ring-${_vm.ringColor}`,
						_vm.bgColor,
						_vm.showErrorMessage ? 'invalid:border-b-red-500' : 'focus:border-b-green-500'
					],attrs:{"id":_vm.id,"type":_vm.inputType,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":_vm.handleBlur}}),_vm._v(" "),_c('Transition',{attrs:{"name":"fade-and-slide"}},[(_vm.showErrorMessage)?_c('div',{staticClass:"pl-1 mt-1 text-xs italic text-red-500"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.errorMessage)+"\n\t\t\t\t\t")]):_vm._e()]),_vm._v(" "),(_vm.floatingLabel)?_c('label',{class:[
						'absolute left-0 focus-within:left-0 peer-focus:-top-6 -top-6 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:left-0 peer-focus:text-gray-500 peer-focus:text-sm',
						{ 'peer-placeholder-shown:left-2' : _vm.rounded },
						{ '-top-6' : _vm.value.length }
					],attrs:{"for":_vm.id}},[_c('span',{class:[
							_vm.labelSize,
							_vm.labelClass
						]},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.label)+"\n\t\t\t\t\t")]),_vm._v(" "),(_vm.required && _vm.showRequiredAstrisk)?_c('span',{class:[
							_vm.labelSize,
							'text-red-500 lowercase'
						]},[_vm._v("\n\t\t\t\t\t\t*\n\t\t\t\t\t")]):_vm._e()]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.inputType === 'tel')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"px-3 placeholder-gray-500 border-gray-300 rounded-md h-11 focus:outline-none focus:ring-2 focus:border-gray-300",class:[
					_vm.inputClass,
					_vm.borderValidationClass,
					_vm.borderClass,
					{ 'bg-gray-200' : _vm.disabled },
					{ 'pl-8' : _vm.displayIcon && _vm.iconPosition === _vm.LEFT },
					`focus:ring-${_vm.ringColor}`,
					_vm.bgColor
				],attrs:{"type":"tel","autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":(_vm.phoneNumber)},on:{"blur":_vm.handleBlur,"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.inputType === 'textarea')?_c('textarea',{staticClass:"px-3 placeholder-gray-500 border-gray-300 rounded-md h-11 focus:outline-none focus:ring-2 focus:border-gray-300",class:[
					_vm.inputClass,
					_vm.borderValidationClass,
					_vm.borderClass,
					{ 'bg-gray-200' : _vm.disabled },
					{ 'pl-8' : _vm.displayIcon && _vm.iconPosition === _vm.LEFT },
					`focus:ring-${_vm.ringColor}`,
					_vm.bgColor
				],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":_vm.handleBlur}}):_vm._e(),_vm._v(" "),(_vm.icon.length)?[(_vm.icon === _vm.SEARCH)?_c('SearchIcon',{class:[`absolute w-5 h-5 transform -translate-y-1/2 pointer-events-none ${_vm.position}-2 top-1/2`]}):_vm._e()]:_vm._e()]})],2),_vm._v(" "),_c('transition',{attrs:{"name":"fade-and-slide"}},[_vm._t("accordion",function(){return [(_vm.showErrorMessage && !_vm.floatingLabel)?_c('div',{staticClass:"pl-1 text-xs italic text-red-500"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.errorMessage)+"\n\t\t\t")]):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }